import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarDesktop from "./NavbarDesktop";
import AnimatedRoutes from "./AimatedRoutes";
import NavbarMobile from "./NavbarMobile";
import Footer from "./Footer";

export default function App() {
  const [mobileNav, setMobileNav] = useState(false);

  function handleResize() {
    if (window.innerWidth <= 800) {
      setMobileNav(true);
    } else {
      setMobileNav(false);
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setMobileNav(true);
    }

    window.addEventListener("resize", handleResize);

    return () =>{
      window.removeEventListener("resize", handleResize);
    }

  }, []);

  return (
    <>
      <Router>
        
        {!mobileNav ? <NavbarDesktop></NavbarDesktop> : <NavbarMobile></NavbarMobile>}
        <AnimatedRoutes></AnimatedRoutes>
        <Footer></Footer>
      </Router>
    </>
  );
}

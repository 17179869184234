import React from "react";
export default function Kontakt() {
  
  return (
    <section>

      <div className="kontaktbox">
        <img id="kontaktpic" src="./Wolfgang Kerschbaummayr.jpg" alt="" />
        <div className="infodata">
          <h1>Mag. Wolfgang Kerschbaummayr <br /></h1>
          <h3>Geschäftsführer <br /> <br /></h3>
          <div className="showinfo">
            <h3>Telefon: +43 699 10 10 47 80</h3> <h3>E-Mail: wolfgang@perfectliving.co.at</h3>
          </div>
        </div>
      </div>
      <div className="kontaktbox" id="k2">
        <img id="kontaktpic" src="./zoegernitz-grinninger.jpg" alt="" />
        <div className="infodata">
          <h1>Tatjana Grinninger <br /></h1>
          <h3>Kooperationspartnerin Verkauf <br /> <br /></h3>
          <div className="showinfo">
            <h3>Telefon: +43 664 544 588 5</h3> <h3>E-Mail: tatjana@perfectliving.co.at</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from 'react'
import ProjektContainer from './Reusables/ProjektContainer'

export default function Projekte() {
  return (
  

<section>
  <h1>Aktuelle Projekte:</h1>
 
 <ProjektContainer pic="./Rb20.jpg" link={null} sold={true} projekt='Rechte Bahn Gasse 20, 1030 Wien' start='2021' end='2023'></ProjektContainer>
 <ProjektContainer pic="./Zoegernitz.jpg" link={null} sold={true} projekt='Wohnresidenz Zögernitz,  1190 Wien' start='2016' end='2019'></ProjektContainer>
  <ProjektContainer pic="./FMG.jpg" link={null} sold={true} projekt='Friedmanngasse 15, 1160 Wien' start='2017' end='2019'></ProjektContainer>
  <ProjektContainer pic="./Rendering14.jpeg" link={null} sold={true} projekt='Lenneisgasse 3-5, 1140 Wien' start='2016' end='2018'></ProjektContainer>
</section>
   
  )
}


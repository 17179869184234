import React from 'react'

export default function Impressum() { 
  return (

    
    <div><h1>Impressum</h1>
    <h3>Medieninhaber & Herausgeber: Perfectliving Immobilienbeteiligung GmbH <br />
Geschäftsführer:  Wolfgang Kerschbaummayr <br /> <br />

Anschrift: Perfectliving Immobilienbeteiligung GmbH, Wurzingergasse 4/4 - 1180 Wien <br />
E-Mail: wolfgang@perfectliving.co.at <br /> <br />

UID-Nummer: ATU 62492458 <br />
Firmenbuchnummer: 249443d <br />
Firmenbuchgericht: Handelsgericht Wien <br /> <br />

Haftungsausschluß <br />
1. Inhalt des Onlineangebotes <br />
Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern
 <br /> <br />
2. Inhalt des Onlineangebotes <br />
Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern
 <br /> <br />
3. Inhalt des Onlineangebotes <br />
Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen
</h3></div>
  )
}

import React from 'react'
import { Link, NavLink} from 'react-router-dom'

export default function NavbarDesktop() {
  return (
    <>
    <div className="navbarcontainer flex" style={{width:'100%', height:'80px', backgroundColor:'var(backgroundColor)'}}>
      {/* <img src="./Perfect Living Logo.png"  id='logo'alt="" /> */}
      <h2></h2>
      <nav className="desktopnav">
        <li>
          <NavLink className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/'>Home</NavLink>
   
          <NavLink className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/Projekte'>Projekte</NavLink>
   
          <NavLink className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/Kontakt'>Kontakt</NavLink>
   
        </li>
        
      </nav>
    </div>
    </>
  )
}

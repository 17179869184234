import React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import {MdClose} from 'react-icons/md';

export default function NavbarMobile() {
  const [menuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <>
    <AnimatePresence>
    {!menuOpen?
    <motion.div 
    initial={{ opacity:0 }}
    animate={{ opacity:1 }}
    transition={{ delay: .5 }}
    exit={{ left: window.innerWidth }}
    key='mobileNav' className="mobilenavbarcontainer">
      <div className="mobilelogo">
      </div>
      <AiOutlineMenu onClick={openMenu} id='menuButton' size={37}></AiOutlineMenu>
    </motion.div>: <motion.div key="mobileMenu" className="mobileMenu" initial={{x: window.innerWidth}} animate={{x:0}} exit={{x:500, transition:{duration:1}}} transition={{delay:.1, duration:.5,}} >
     <MdClose onClick={openMenu} id="closeButton" size={40}></MdClose>
    <nav className="mobilenav">
        <li>
          <NavLink onClick={openMenu}className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/'>Home</NavLink>
   
          <NavLink onClick={openMenu} className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/Projekte'>Projekte</NavLink>
   
          <NavLink onClick={openMenu} className={({isActive})=> isActive? 'linkactive' : undefined
          } to='/Kontakt'>Kontakt</NavLink>
   
        </li>
        
      </nav></motion.div>}
      </AnimatePresence>
  </>
  );
}

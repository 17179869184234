import { AnimatePresence } from 'framer-motion';
import React from 'react'
import { Routes,Route, useLocation } from 'react-router-dom'
import Home from './Home';
import Impressum from './Impressum';
import Kontakt from './Kontakt';
import Projekte from './Projekte';
import Pagetransition from './Reusables/Pageanimation';

export default function AimatedRoutes({children}) {
    const location = useLocation();
  return (
    <AnimatePresence initial='false' mode='wait'>
    <Routes location={location} key={location.key}>
    <Route path='/' element={<Pagetransition><Home></Home></Pagetransition>} ></Route>
    <Route path='/Projekte' element={<Pagetransition><Projekte></Projekte></Pagetransition>} ></Route>
    <Route path='/Kontakt' element={<Pagetransition><Kontakt></Kontakt></Pagetransition>} ></Route>
    <Route path='/Impressum' element={<Pagetransition><Impressum></Impressum></Pagetransition>} ></Route>
    </Routes>
  </AnimatePresence>
  )
}

import React from "react";
import { useHref } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

export default function ProjektContainer(props) {
  const sold = props.sold
  const link = props.link;
  const pic = props.pic

const [activeHover, setActiveHover] = useState(false);

  const hanldehover = () => {
    if (sold) {
      
     setActiveHover(true);
    }
  };

  let style = {
    backgroundImage: `url(${props.pic})`
  };

  if (link) {
    style.cursor = "pointer";
  }

console.log(style)
  return (
    <div
      className="projektContainer"

      onClick={link ? () => window.open("www.google.com") : null}
     style={style}
      onMouseOver={hanldehover}
      onMouseOut ={()=>setActiveHover(false)}
      onTouchStart={hanldehover}
      onTouchEnd ={()=>setActiveHover(false)}
  
    >
      <div className="layer">
        <h2>{props.projekt}</h2>
        <h3>Baubeginn: {props.start}</h3>
        <h3>Fertigstellung: {props.end}</h3>
      </div>
      <AnimatePresence key={'xnsj'}>
      {activeHover?
   
      <motion.div key={'kdldasdj'} initial={{opacity:0}} animate={{opacity:1}}exit={{opacity:0}} transition={{duration:.8}}  className="soldlayer">
        <h1>Verkauft</h1>
      </motion.div>
   
      :null}
         </AnimatePresence>
    </div>
  );
}

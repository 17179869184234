import React from 'react'
import { motion } from 'framer-motion'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useEffect } from 'react'
import gsap from 'gsap'

export default function Pagetransition({children}) {


    useEffect(()=>{
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
          }
    },[])


    useEffect(()=>{
window.scrollTo(0,0);
ScrollTrigger.refresh();
    },[])
    return (
        <motion.div onAnimationComplete={()=>[gsap.registerPlugin(ScrollTrigger),ScrollTrigger.refresh()]} className='pagetransition' transition={{ease: 'easeIn', duration: .3}} initial={{opacity:0, x:(window.innerWidth*-1)}} animate={{opacity:1,x:0,}} exit={{ opacity:0, x:window.innerWidth}}> 
            {children}
        </motion.div>
    )

}
